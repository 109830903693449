import _objectSpread from "/Users/lucky.zhang/workspaces/workspace_go/src/routerman/web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
// import ChooseImg from "@/components/chooseImg";
import { changePassword } from "@/api/user";
import { mapGetters, mapMutations } from "vuex";
import { store } from '@/store/index';
import { encrypt } from '@/utils/crypto';
var path = process.env.VUE_APP_BASE_API;
export default {
  name: "Person",
  data: function data() {
    var _this = this;
    return {
      path: path,
      activeName: "second",
      showPassword: false,
      pwdModify: {},
      lang: '',
      // langValue: false,
      rules: {
        password: [{
          required: true,
          message: this.$t('person-setting.check-error-empty-pwd'),
          trigger: "blur"
        }, {
          min: 6,
          message: this.$t('person-setting.check-error-length-pwd'),
          trigger: "blur"
        }],
        newPassword: [{
          required: true,
          message: this.$t('person-setting.check-error-empty-newpwd'),
          trigger: "blur"
        }, {
          min: 6,
          message: this.$t('person-setting.check-error-length-pwd'),
          trigger: "blur"
        }],
        confirmPassword: [{
          required: true,
          message: this.$t('person-setting.check-error-empty-cfpwd'),
          trigger: "blur"
        }, {
          min: 6,
          message: this.$t('person-setting.check-error-length-pwd'),
          trigger: "blur"
        }, {
          validator: function validator(rule, value, callback) {
            if (value !== _this.pwdModify.newPassword) {
              callback(new Error(_this.$t('person-setting.check-error-invalid-pwd')));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }]
      }
    };
  },
  // components: {
  //   ChooseImg
  // },
  computed: _objectSpread({}, mapGetters("user", ["userInfo", "token"])),
  methods: _objectSpread(_objectSpread({}, mapMutations("user", ["ResetUserInfo"])), {}, {
    savePassword: function savePassword() {
      var _this2 = this;
      this.$refs.modifyPwdForm.validate(function (valid) {
        if (valid) {
          changePassword({
            username: _this2.userInfo.userName,
            password: encrypt(_this2.pwdModify.password),
            newPassword: encrypt(_this2.pwdModify.newPassword),
            lastTeamId: _this2.userInfo.lastTeamId
          }).then(function (res) {
            if (res.code == 0) {
              _this2.$message.success(_this2.$t('person-setting.msg-success'));
            }
            _this2.showPassword = false;
          });
        } else {
          return false;
        }
      });
    },
    clearPassword: function clearPassword() {
      this.pwdModify = {
        password: "",
        newPassword: "",
        confirmPassword: ""
      };
      this.$refs.modifyPwdForm.clearValidate();
    },
    openChooseImg: function openChooseImg() {
      this.$refs.chooseImg.open();
    },
    // async enterImg(url) {
    //   const res = await setUserInfo({ headerImg: url, ID: this.userInfo.ID });
    //   if (res.code == 0) {
    //     this.ResetUserInfo({ headerImg: url });
    //     this.$message({
    //       type: "success",
    //       message: "设置成功"
    //     });
    //   }
    // },
    translate: function translate() {
      // console.log(this.lang)
      // location.reload()
      // save
      // localStorage.setItem('language', this.lang)
      // location.reload()
      this.$i18n.locale = this.lang;
      store.commit('language/setLanguage', this.lang);
    }
  }),
  created: function created() {
    this.lang = store.getters['language/language'];
  }
};