var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "user-addcount"
  }, [_c("ul", [_c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("person-setting.pwd")))]), _c("p", {
    staticClass: "desc"
  }, [_vm._v(" " + _vm._s(_vm.$t("person-setting.modify-pwd-desc")) + " "), _c("a", {
    attrs: {
      href: "javascript:void(0);"
    },
    on: {
      click: function click($event) {
        _vm.showPassword = true;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-edit"
  }), _vm._v(_vm._s(_vm.$t("person-setting.modify-pwd")))])])]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("person-setting.language")))]), _c("p", {
    staticClass: "desc"
  }, [_vm._v(" " + _vm._s(_vm.$t("person-setting.language-desc")) + " "), _c("span", [_c("el-switch", {
    staticStyle: {
      display: "block"
    },
    attrs: {
      "active-color": "#13ce66",
      "inactive-color": "#409eff",
      "active-text": "中文",
      "inactive-text": "English",
      "active-value": "cn",
      "inactive-value": "en"
    },
    on: {
      change: _vm.translate
    },
    model: {
      value: _vm.lang,
      callback: function callback($$v) {
        _vm.lang = $$v;
      },
      expression: "lang"
    }
  })], 1)])])])])])], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.showPassword,
      title: _vm.$t("person-setting.modify-dlg-title")
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showPassword = $event;
      },
      close: _vm.clearPassword
    }
  }, [_c("el-form", {
    ref: "modifyPwdForm",
    attrs: {
      model: _vm.pwdModify,
      rules: _vm.rules,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("person-setting.old-pwd"),
      prop: "password"
    }
  }, [_c("el-input", {
    attrs: {
      "show-password": ""
    },
    model: {
      value: _vm.pwdModify.password,
      callback: function callback($$v) {
        _vm.$set(_vm.pwdModify, "password", $$v);
      },
      expression: "pwdModify.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("person-setting.new-pwd"),
      prop: "newPassword"
    }
  }, [_c("el-input", {
    attrs: {
      "show-password": ""
    },
    model: {
      value: _vm.pwdModify.newPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.pwdModify, "newPassword", $$v);
      },
      expression: "pwdModify.newPassword"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("person-setting.confirm-pwd"),
      prop: "confirmPassword"
    }
  }, [_c("el-input", {
    attrs: {
      "show-password": ""
    },
    model: {
      value: _vm.pwdModify.confirmPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.pwdModify, "confirmPassword", $$v);
      },
      expression: "pwdModify.confirmPassword"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.showPassword = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.cancel")))]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.savePassword
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.confirm")))])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };